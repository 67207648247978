
import {Component, Vue} from 'vue-property-decorator';
import AccessoryItem from '@/components/AccessoryItem.vue';

@Component<Accessory>({
    components: {AccessoryItem},
    metaInfo()
    {
        return {
            title: 'Příslušenství',
            titleTemplate: '%s | Půjčovna Haryků',
        };
    },
})
export default class Accessory extends Vue
{
    private accessories: object[] = [
        {
            image: require('@/assets/images/accessories/jacket_lime_neo_005.jpg'),
            name: 'Bunda Lime Neo - 3 vrstvy',
            category: 'Bunda',
            size: 'Velikost L/XL',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/parado_slim_bl_007.jpg'),
            name: 'Kevlarové kalhoty TRILOBITE',
            category: 'Kalhoty',
            size: 'Velikost 34',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/micas_urban_004.jpg'),
            name: 'Kevlarové jeansy TRILOBITE',
            category: 'Kalhoty',
            size: 'Velikost 34',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/modeka_speed_tech_schwarz-rot_ml_006.jpg'),
            name: 'Modeka SPEED TECH',
            category: 'Boty',
            size: 'Velikost 44',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/shark_helmet_008.png'),
            name: 'Shark Dual Drak MAT',
            category: 'Helma',
            size: 'Velikost M',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/firefly_helmet_001.png'),
            name: 'Výklopná přilba LS2',
            category: 'Helma',
            size: 'Velikost M',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/gloves_001.png'),
            name: 'Letní Harley Davidson',
            category: 'Rukavice',
            size: 'Velikost L',
            price: 0,
        },
        {
            image: require('@/assets/images/accessories/gloves_002.png'),
            name: 'Lime univerzální kožené',
            category: 'Rukavice',
            size: 'Velikost L',
            price: 0,
        },
    ];
}
