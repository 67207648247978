
    import {Component, Prop, Vue} from 'vue-property-decorator';

    @Component
    export default class AccessoryItem extends Vue
    {
        @Prop({required: true})
        private image?: string;

        @Prop({required: true})
        private name?: string;

        @Prop({required: true})
        private category?: string;

        @Prop({required: true})
        private size?: string;

        @Prop({required: true})
        private price?: number;
    }
